import React, { Component } from 'react';
import Chart from 'chart.js';
import PropTypes from 'prop-types';
import deepmerge from 'deepmerge';
import { combineArrays } from '../../../helpers/deepmerge';

let consumptionChart;

const defaultChartOptions = {
  responsive: true,
  maintainAspectRatio: false,
  legend: {
    display: false,
  },
  legendCallback() {
    return '<div class="chart-legend">'
      + '<div class="consumption-label consumption-label-real">Reelt forbrug</div>'
      + '<div class="consumption-label consumption-label-estimated">Forventet forbrug</div>'
      + '</div>';
  },
  scales: {
    xAxes: [{
      categoryPercentage: 0.5,
      barPercentage: 0.75,
      maxBarThickness: 11,
      gridLines: {
        display: false,
      },
      ticks: {
        fontFamily: "'SF Compact Display Regular'",
        fontSize: 11,
        fontStyle: 'bold',
        padding: 10,
      },
    }],
    yAxes: [{
      gridLines: {
        color: 'rgba(0, 0, 0, 0.1)',
        drawTicks: false,
        drawBorder: false,
      },
      ticks: {
        beginAtZero: true,
        suggestedMax: 200,
        fontFamily: "'SF Compact Display Regular'",
        fontSize: 11,
        fontStyle: 'bold',
        padding: 10,
        callback(value) {
          return `${value} kWh`;
        },
      },
    }],
  },
};

class ConsumptionChart extends Component {
    chartRef = React.createRef();

    legendRef = React.createRef();

    componentDidMount() {
      this.buildChart();
    }

    componentDidUpdate() {
      this.buildChart();
    }

    buildChart = () => {
      const myChartRef = this.chartRef.current.getContext('2d');
      const {
        chartLibOptions,
        labels,
        estimatedConsumptionData,
        realConsumptionData,
      } = this.props;

      if (typeof consumptionChart !== 'undefined') consumptionChart.destroy();

      consumptionChart = new Chart(myChartRef, {
        type: 'bar',
        data: {
          labels,
          datasets: [
            {
              label: 'Reelt forbrug',
              data: realConsumptionData,
              backgroundColor: '#e85f21',
              borderColor: '#e85f21',
            },
            {
              label: 'Forventet forbrug',
              data: estimatedConsumptionData,
              backgroundColor: '#2f80f6',
              borderColor: '#2f80f6',
            },
          ],
        },
        options: deepmerge(defaultChartOptions, chartLibOptions, { arrayMerge: combineArrays }),
      });

      this.legendRef.current.innerHTML = consumptionChart.generateLegend();
    };

    render() {
      return (
        <div className="consumptionChartWrapper">
          <div ref={this.legendRef} className="consumptionChartLegend" />
          <div>
            <canvas
              id="consumptionChart"
              ref={this.chartRef}
            />
          </div>
        </div>
      );
    }
}

ConsumptionChart.propTypes = {
  labels: PropTypes.arrayOf(PropTypes.string),
  estimatedConsumptionData: PropTypes.arrayOf(PropTypes.number),
  realConsumptionData: PropTypes.arrayOf(PropTypes.number),
  chartLibOptions: PropTypes.object,
};

ConsumptionChart.defaultProps = {
  labels: [],
  estimatedConsumptionData: [],
  realConsumptionData: [],
  chartLibOptions: {},
};

export default ConsumptionChart;
