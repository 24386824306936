import React from 'react';
import { Provider as StoreProvider } from 'react-redux';
import { toast, ToastContainer } from 'react-toastify';
import { SelfcareDataStore } from './store/DataStore';
import SelfcareConnector from './store/connectors/SelfcareConnector';
import './assets/sass/app.scss';
import 'react-toastify/scss/main.scss';
import { CookiesProvider } from 'react-cookie';

const App = () => (
  <>
    <CookiesProvider>
      <StoreProvider store={SelfcareDataStore}>
        <SelfcareConnector />
      </StoreProvider>
      <ToastContainer
        position={toast.POSITION.TOP_RIGHT}
        hideProgressBar
      />
    </CookiesProvider>
  </>
);

export default App;
