import React from 'react';
import { Badge, FormControl, Table } from 'react-bootstrap';
import Image from 'react-bootstrap/Image';
import Button from 'react-bootstrap/Button';
import { toast } from 'react-toastify';
import PropTypes from 'prop-types';
import { NavLink } from 'react-router-dom';
import Paginator from '../common/Paginator';
import { formatIsoDate } from '../../helpers/formats';
import DashboardSection from './DashboardSection';
import InvoicesIcon from '../../assets/images/icons/dashboardPage/invoicePanelIcon.png';
import PdfIcon from '../../assets/images/icons/common/pdfIcon.png';
import SearchInvoicesIcon from '../../assets/images/icons/dashboardPage/searchInvoicesIcon.png';
import Api from '../../network/api';
import download from '../../helpers/files';
import OnlinePaymentModal from '../common/payments/OnlinePaymentModal';
import MoneyCell from '../common/tables/MoneyCell';
import { getStatusDanishTranslation } from '../../helpers/translations';
import InvoiceStatusType from '../../types/InvoiceStatusType';


class InvoicePanel extends React.Component {
  constructor(props) {
    super(props);

    this.state = {
      currentPage: 1,
      pageLimit: 5,
      firstVisibleRecord: 0,
      lastVisibleRecord: 0,
      filter: '',
      selectedInvoice: {},
    };

    this.displayCurrentPage = this.displayCurrentPage.bind(this);
    this.setInvoiceFilter = this.setInvoiceFilter.bind(this);
    this.filter = React.createRef();
    this.onlinePaymentModalRef = React.createRef();
  }


  static invoiceHasVerifiedPayment(invoice) {
    return invoice.balance === 0;
  }

  get filteredInvoices() {
    const { invoices } = this.props;
    const { filter } = this.state;

    return invoices.filter(
      invoice => filter === '' || invoice.invoice_no.includes(filter),
    );
  }

  static getDerivedStateFromProps(nextProps, prevState) {
    const numFilteredInvoices = nextProps.invoices.filter(
      invoice => prevState.filter === '' || invoice.invoice_no.includes(prevState.filter),
    ).length;

    return {
      lastVisibleRecord: Math.min(
        numFilteredInvoices - 1,
        (prevState.currentPage * prevState.pageLimit) - 1,
      ),
    };
  }

  setInvoiceFilter() {
    this.setState({
      currentPage: 1,
      filter: this.filter.current.value,
      firstVisibleRecord: 0,
    });
  }

  displayCurrentPage(paginationData) {
    const { invoices } = this.props;

    this.setState({
      currentPage: paginationData.currentPage,
      firstVisibleRecord: (paginationData.currentPage - 1) * paginationData.pageLimit,
      lastVisibleRecord: Math.min(
        invoices.length,
        paginationData.currentPage * paginationData.pageLimit,
      ),
    });
  }

  canPayInvoice(invoice) {
    return invoice.balance > 0;
  }

  openOnlinePaymentModal = (invoice) => {
    this.setState(
      { selectedInvoice: invoice },
      () => { this.onlinePaymentModalRef.current.open(); },
    );
  }

  renderAction(invoice) {
    return this.canPayInvoice(invoice)
      ? <Button variant="link" onClick={() => { this.openOnlinePaymentModal(invoice); }}>Betal nu</Button>
      : '';
  }

  handleInvoiceDownload = (invoice) => {
    Api.get(`/invoices/${invoice.invoice_no}`, { responseType: 'blob' })
      .then((response) => {
        download(response.data, `${invoice.invoice_no}.pdf`);
      })
      .catch(() => {
        toast.error('Der opstod en fejl under download af fakturaen');
      });
  }

  getStatusColor(status) {
    switch (status) {
      case 'collected':
        return 'warning';
      case 'credited':
        return 'info';
      case 'unpaid':
        return 'danger';
      case 'paid':
        return 'success';
      case 'unknown':
      default:
        return 'secondary';
    }
  }

  convertAgreement_no(n, width, z) {
    z = z || '0';
    n = n + '';
      return n.length >= width ? "A" + n : "A" + new Array(width - n.length + 1).join(z) + n;
  }


  renderStatus(invoice) {
    return (
      <Badge variant={invoice.price > 0 ? this.getStatusColor(invoice.status) : 'info'}>
        {invoice.price > 0 ? getStatusDanishTranslation(invoice.status) : 'Kreditnota'}
      </Badge>
    );
  }

  render() {

    const {
      currentPage, pageLimit, selectedInvoice, firstVisibleRecord, lastVisibleRecord, filter,
    } = this.state;
    const rows = [];

    this.filteredInvoices.forEach((invoice, index) => {
      if ((index >= firstVisibleRecord) && (index <= lastVisibleRecord)) {
        if (filter === '' || invoice.invoice_no.includes(filter)) {
          rows.push(
            <tr key={invoice.invoice_no}>
              <td>{formatIsoDate(invoice.invoice_date)}</td>
              <td><NavLink to={`/agreements/${invoice.agreement_id}`}>{this.convertAgreement_no(invoice.agreement_id, 8)}</NavLink></td>
              <td>Faktura {invoice.invoice_no}</td>
              <td>
                <Button variant="link" onClick={() => this.handleInvoiceDownload(invoice)}>
                  Se faktura
                  <Image className="pdfInvoiceIcon" src={PdfIcon} />
                </Button>
              </td>
              <td>{this.renderStatus(invoice)}</td>
              <td>{this.renderAction(invoice)}</td>
              <MoneyCell
                currency="DKK"
                amount={invoice.price_incl_vat}
              />
            </tr>,
          );
        }
      }
    });

    return (
      <>
        <DashboardSection
          className="invoices-panel"
          headerIcon={InvoicesIcon}
          headerText="Dine senester regninger"
          headerToolbar={(
            <span className="invoice-search-field d-none d-lg-flex">
              <Image src={SearchInvoicesIcon} />
              <span htmlFor="invoices-filter-input">Søg faktura</span>
              <FormControl
                id="invoices-filter-input"
                ref={this.filter}
                placeholder="Indtast fakturanummer"
                aria-label="Indtast fakturanummer"
              />
              <Button onClick={() => this.setInvoiceFilter()}>Søg</Button>
            </span>
          )}
        >
          <Table striped borderless responsive={true}>
            <thead>
              <tr>
                <th>Dato</th>
                <th>Aftalenr.</th>
                <th>Beskrivelse</th>
                <th>Faktura</th>
                <th>Status</th>
                <th>Handling</th>
                <th>Beløb</th>
              </tr>
            </thead>
            <tbody>
              {
                this.filteredInvoices.length > 0
                  ? rows
                  : (<tr><td colSpan="7">Der er ingen fakturaer</td></tr>)
              }
            </tbody>
          </Table>
          <Paginator
            currentPage={currentPage}
            pageLimit={pageLimit}
            totalRecords={this.filteredInvoices.length}
            onPageChanged={this.displayCurrentPage}
          />
        </DashboardSection>
        <OnlinePaymentModal
          ref={this.onlinePaymentModalRef}
          amount={selectedInvoice.balance}
          orderId={selectedInvoice.entry_no}
          callbackUrl={process.env.REACT_APP_EPAY_CALLBACK_URL}
          merchantNumber={process.env.REACT_APP_EPAY_MERCHANT_NUMBER}
          acceptUrl={
            `${process.env.REACT_APP_EPAY_PAYMENT_ACCEPT_HOST}/invoices/${selectedInvoice.invoice_no}/paid`
          }
        />
      </>
    );
  }
}

InvoicePanel.propTypes = {
  invoices: PropTypes.arrayOf(PropTypes.object).isRequired,
};

export default InvoicePanel;
