import React, { Component } from 'react';
import {
  BrowserRouter, Redirect, Route, Switch,
} from 'react-router-dom';
import { connect } from 'react-redux';
import GenerateNewPasswordPage from '../../pages/GenerateNewPasswordPage';
import DashboardPageConnector from './dashboard/DashboardPageConnector';
import { setAuthenticatedUser } from '../actions/authActions';
import AgreementsPageConnector from './agreements/AgreementsPageConnector';
import ConsumptionPageConnector from './consumption/ConsumptionPageConnector';
import ProfilePageConnector from './profile/ProfilePageConnector';
import PaidInvoicePageConnector from './invoices/PaidInvoicePageConnector';
import DashboardPaidInvoicePageConnector from './invoices/DashboardPaidInvoicePageConnector';
import LoginPageConnector from './auth/LoginPageConnector';

const mapStateToProps = state => ({
  ...state,
});

const mapDispatchToProps = dispatch => ({
  setAuthenticatedUser: user => dispatch(setAuthenticatedUser(user)),
});

const RedirectComponent = (props) => {

  let id = props.match.params.id; 
  let url = "https://aftale.blueenergy.dk/ordre/" + id; 
  window.location.href = url
  return <div></div>
}
const SelfcareConnector = connect(mapStateToProps, mapDispatchToProps)(
  class extends Component {
    isLoggedIn() {
      const { auth } = this.props;

      return auth.user !== null;
    }

    renderRoutes() {
      if (!this.isLoggedIn()) {
        return (
          <Switch>
            <Route
            exact path="/ordre/:id"
            render={routeProps => <RedirectComponent {...this.props} {...routeProps} />}
            />
            <Route
              path="/generate-password"
              render={routeProps => <GenerateNewPasswordPage {...this.props} {...routeProps} />}
            />
            <Route path="/login" render={routeProps => <LoginPageConnector {...this.props} {...routeProps} />} />
            <Redirect to="/login" />
          </Switch>
        );
      }
      return (
        <Switch>
          <Route
            exact path="/ordre/:id"
            render={routeProps => <RedirectComponent {...this.props} {...routeProps} />}
            />
          <Route exact path="/" render={routeProps => <DashboardPageConnector {...this.props} {...routeProps} />} />
          <Route
            path="/agreements/:agreementId/invoices/:invoiceNo/paid"
            render={routeProps => <PaidInvoicePageConnector {...this.props} {...routeProps} />}
          />
          <Route
            path="/invoices/:invoiceNumber/paid"
            render={
              routeProps => <DashboardPaidInvoicePageConnector {...this.props} {...routeProps} />
            }
          />
          <Route path="/agreements/:agreementId" render={routeProps => <AgreementsPageConnector {...this.props} {...routeProps} />} />
          <Route path="/agreements" render={routeProps => <AgreementsPageConnector {...this.props} {...routeProps} />} />
          <Route path="/consumption" render={routeProps => <ConsumptionPageConnector {...this.props} {...routeProps} />} />
          <Route path="/profile" render={routeProps => <ProfilePageConnector {...this.props} {...routeProps} />} />
          <Redirect to="/" />
        </Switch>
      );
    }

    render() {
      return (
        <BrowserRouter>
          {this.renderRoutes()}
        </BrowserRouter>
      );
    }
  },
);

export default SelfcareConnector;
