import React from 'react';
import { Form, Button, InputGroup } from 'react-bootstrap';
import PropTypes from 'prop-types';
import * as Yup from 'yup';
import { Formik } from 'formik';
import Api from '../../../network/api';

const PaymentServiceFormSchema = Yup.object().shape({
  account_number: Yup.string().required(),
  reg_number: Yup.string().required(),
  cpr_number: Yup.string().required(),
})

class AddBetalingServiceAccountForm extends React.Component {

  constructor(props) {
  super(props);
  this.state = {submitted: false};
}

checkForError = () => {
  this.setState({submitted: true});
}

  render() {
    const { onCancel, onSubmit } = this.props;

    return (
      <Formik
        initialValues={{
          account_number: '',
          reg_number: '',
          cpr_number: '',
        }}
        validationSchema={PaymentServiceFormSchema}
        onSubmit={(values) => { onSubmit(values); }}
      >
        {({
          handleSubmit,
          handleChange,
          values,
          errors,
        }) => (
          <Form noValidate onSubmit={handleSubmit}>
            <Form.Group controlId="formGroupAccountNumber">
              <Form.Label>Reg.nr:</Form.Label>
              <Form.Control
                type="text"
                size="sm"
                name="reg_number"
                value={values.reg_number}
                isInvalid={this.state.submitted ? !!errors.reg_number : false}
                onChange={handleChange}
                placeholder="Reg.nr."
              />
              <Form.Control.Feedback type="invalid">
                {errors.account_number}
              </Form.Control.Feedback>
            </Form.Group>

            <Form.Group controlId="formGroupRegNumber">
              <Form.Label>Kontonummer:</Form.Label>
              <Form.Control
                type="text"
                size="sm"
                name="account_number"
                value={values.account_number}
                isInvalid={this.state.submitted ? !!errors.account_number : false}
                onChange={handleChange}
                placeholder="Kontonummer"
              />
              <Form.Control.Feedback type="invalid">
                {errors.reg_number}
              </Form.Control.Feedback>
            </Form.Group>

            <Form.Group controlId="formGroupCPR">
              <Form.Label>CPR nr.:</Form.Label>
              <Form.Control
                type="text"
                size="sm"
                name="cpr_number"
                value={values.cpr_number}
                isInvalid={this.state.submitted ? !!errors.cpr_number : false}
                onChange={handleChange}
                placeholder="CPR"
              />
              <Form.Control.Feedback type="invalid">
                {errors.cpr_number}
              </Form.Control.Feedback>
            </Form.Group>
            <Form.Row className="actions-panel">
              <Button variant="secondary" type="button" onClick={onCancel}>Afbestille</Button>
              <Button variant="primary" type="submit" onClick={this.checkForError}>Accepter</Button>
            </Form.Row>
          </Form>
        )}
      </Formik>
    );
  }
}

AddBetalingServiceAccountForm.propTypes = {
  onCancel: PropTypes.func.isRequired,
  onSubmit: PropTypes.func.isRequired,
};

export default AddBetalingServiceAccountForm;
