import React from 'react';
import {
  Button, Col, Container, Image, Modal, Row,
} from 'react-bootstrap';
import PropTypes from 'prop-types';
import MasterCardLogo from '../../../assets/images/payment/mastercard-color.png';
import VisaLogo from '../../../assets/images/payment/visa-color.png';
import DankortLogo from '../../../assets/images/payment/dankort-color.png';
import EDankortLogo from '../../../assets/images/payment/eDankort-color.png';
import BetalingsserviceLogo from '../../../assets/images/payment/betalingsservice.png';
import AddBetalingServiceAccountForm from './AddBetalingServiceAccountForm';
import OnlinePaymentModal from './OnlinePaymentModal';
import Api from '../../../network/api';
import HeaderIcon from '../../../assets/images/icons/agreementsPage/addSubscriptionIcon.png';

class AddPaymentMethodModal extends React.Component {
  constructor(props) {
    super(props);

    this.addCreditCardSubscriptionModalRef = React.createRef();

    this.state = {
      isPaymentServiceOpen: false,
    };
  }

  close = () => {
    const { onHide } = this.props;

    this.hidePaymentServiceCreator();
    onHide();
  }

  openNewCreditCardSubscriptionModal = () => {
    this.close();
    this.addCreditCardSubscriptionModalRef.current.open();
  }

  showPaymentServiceCreator = () => {
    this.setState({ isPaymentServiceOpen: true });
  }

  hidePaymentServiceCreator = () => {
    this.setState({ isPaymentServiceOpen: false });
  }

  addBetalingService = (data) => {
    const { agreement } = this.props;
    Api.post(`/agreements/${agreement.number}/betaling-service-accounts`, data)
      .then(() => {
        this.close();
      });
  }

  render() {
    const { isOpen, agreement } = this.props;
    const { isPaymentServiceOpen } = this.state;

    return ( 
      <Modal show={isOpen} onHide={this.close} size="lg" className="private-modal-dialog">
        <Modal.Header closeButton>
          <Image src={HeaderIcon} />
          <Modal.Title>Betalingsoplysninger</Modal.Title>
        </Modal.Header>
        <Modal.Body>
          <Container>
            <Row className="payment-type-header">
              <span>Vælg betalingstype</span>
            </Row>
            <Row className="payment-type-selectors">
              <Col sm="6" className="payment-type-selector">
                <div className="card-logos">
                  <Image src={MasterCardLogo} alt="MasterCard" />
                  <Image src={VisaLogo} alt="Visa" />
                  <Image src={DankortLogo} alt="Dankort" />
                  <Image src={EDankortLogo} alt="eDankort" />
                </div>
                <div className="title">
                  Betalingskort
                </div>
                <Button variant="primary" onClick={() => { this.openNewCreditCardSubscriptionModal(); }}>
                  Tilføj betalingskort
                </Button>
                <OnlinePaymentModal
                  ref={this.addCreditCardSubscriptionModalRef}
                  callbackUrl={process.env.REACT_APP_EPAY_SUBSCRIPTION_CALLBACK_URL_HOST + `/agreements/${agreement.number}/epay-credit-cards`}
                  merchantNumber={process.env.REACT_APP_EPAY_MERCHANT_NUMBER}
                  acceptUrl={process.env.REACT_APP_EPAY_SUBSCRIPTION_ACCEPT_URL}
                  amount={0}
                  isSubscription={true}
                />
              </Col>
              <Col sm="6" className="payment-type-selector">
                <div>
                  <img src={BetalingsserviceLogo} alt="Betalingsservice" />
                </div>
                <div className="title">
                  Betalingsservice
                </div>
                <Button
                  variant="primary"
                  disabled={isPaymentServiceOpen}
                  onClick={this.showPaymentServiceCreator}
                >
                  Tilmeld betalingsservice
                </Button>
              </Col>
            </Row>
            <Row>
              <Col sm={{ span: 6, offset: 6 }} className="new-betalingservice-account">
                {isPaymentServiceOpen ? (
                  <AddBetalingServiceAccountForm
                    onCancel={this.hidePaymentServiceCreator}
                    onSubmit={this.addBetalingService}
                    agreementId={agreement.number}
                  />
                ) : null}
              </Col>
            </Row>
          </Container>
        </Modal.Body>
      </Modal>
    );
  }
}

AddPaymentMethodModal.propTypes = {
  isOpen: PropTypes.bool.isRequired,
  onHide: PropTypes.func.isRequired,
  agreement: PropTypes.object.isRequired,
};

export default AddPaymentMethodModal;
