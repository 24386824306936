import React from 'react';
import {
  Button, Image, Table,
} from 'react-bootstrap';
import PropTypes from 'prop-types';
import { toast } from 'react-toastify';
import upperFirst from 'lodash/upperFirst';
import BillingPeriodType from '../../types/BillingPeriodType';
import BindngPeriodType from '../../types/BindingPeriodType';
import AddPaymentMethodModal from '../common/payments/AddPaymentMethodModal';
import Api from '../../network/api';
import editPaymentMethodIcon from '../../assets/images/icons/agreementsPage/pencilBlue.png';

class SubscriptionSummary extends React.Component {
  constructor(props) {
    super(props);

    this.state = {
      isAddPaymentMethodModalOpen: false,
    };
  }

  openAddPaymentMethodModal = () => {
    this.setState({ isAddPaymentMethodModalOpen: true });
  }

  closeAddPaymentMethodModal = () => {
    this.setState({ isAddPaymentMethodModalOpen: false });
  }

  handleSendInvoiceOnEmailUpdate = (ev) => {
    const { agreement, updateAgreement } = this.props;
    Api
      .patch(
        `/agreements/${agreement.number}/send-invoice-on-email`,
        { send_invoice_on_email: ev.target.checked },
      )
      .then(({ data }) => {
        updateAgreement(data);
      })
      .catch(() => {
        toast.error('Der opstod en fejl under ændring af afsendelse af faktura i e-mail-feltet');
      });
  }

  render() {
    const { agreement } = this.props;
    const { isAddPaymentMethodModalOpen } = this.state;
    console.log(agreement);
    return (
      <section className="agreement-subscription-section">
        <header>Aftaleinformation</header>
        <Table borderless>
          <thead>
            <tr>
              <th>Fakturaadresse</th>
              <th>Betalingsperiode</th>
              <th>Betalingsmetode</th>
              <th>Faktura via email</th>
            </tr>
          </thead>
          <tbody>
            <tr>
              <td>{agreement.address}</td>
              <td>{this.getBillingPeriod(agreement)}</td>
              <td>
                <Button variant="link" onClick={this.openAddPaymentMethodModal}>
                  {this.getBillingMethod(agreement)}
                  <Image src={editPaymentMethodIcon} />
                </Button>
              </td>
              <td>
                <div className="custom-control custom-checkbox">
                  <input
                    type="checkbox"
                    className="custom-control-input"
                    id={`sendInvoiceOnEmail-${agreement.id}`}
                    checked={agreement.send_invoice_on_email}
                    onChange={this.handleSendInvoiceOnEmailUpdate}
                  />
                  <label className="custom-control-label" htmlFor={`sendInvoiceOnEmail-${agreement.id}`}>
                    {agreement.email}
                  </label>
                </div>
              </td>
            </tr>
          </tbody>
        </Table>
        <AddPaymentMethodModal
          isOpen={isAddPaymentMethodModalOpen}
          onHide={this.closeAddPaymentMethodModal}
          agreement={agreement}
        />
      </section>
    );
  }

  // getBindingPeriod(agreement) {
  //   switch (agreement.binding_period) {
  //     case BindngPeriodType.no_binding:
  //       return 'Ingen bud';
  //     case BillingPeriodType.six_months:
  //       return '6 mdr.';
  //     case BillingPeriodType.one_year:
  //       return '1 år';
  //     case BillingPeriodType.two_years:
  //       return '2 år';
  //     case BillingPeriodType.three_years:
  //       return '3 år';
  //     default:
  //       return 'Ukendt';
  //   }
  // }

  getBillingPeriod(agreement) {
    console.log(agreement);
    switch (agreement.billing_period) {
      case BillingPeriodType.monthly:
        return 'Månedlige';
      case BillingPeriodType.quarterly:
        return 'Kvartalsvis';
      case BillingPeriodType.half_yearly:
        return 'Halvårligt';
      default:
        return 'Ukendt';
    }
  }

  getBillingMethod(agreement) {
    return agreement.billing_methods && agreement.billing_methods.length
      ? upperFirst(agreement.billing_methods[agreement.billing_methods.length - 1].type)
      : '';
  }
}

SubscriptionSummary.propTypes = {
  agreement: PropTypes.object.isRequired,
  updateAgreement: PropTypes.func.isRequired,
};

export default SubscriptionSummary;
