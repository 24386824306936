import React from 'react';
import Navbar from 'react-bootstrap/Navbar';
import logo from '../../../assets/images/brand/logo.png';

class PublicTopNavBar extends React.Component {
  render() {
    return (
      <Navbar>
        <Navbar.Brand className="mx-auto">
          <img
            src={logo}
            alt="Blue Energy logo"
          />
        </Navbar.Brand>
      </Navbar>
    );
  }
}

export default PublicTopNavBar;
